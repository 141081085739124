<template>
    <v-main>
      <div class="main-content">
      <Card class="sticky-filters card-custom">
        <template #title>
          <Toolbar>
            <template #start>
              <div class="d-flex justify-center">
                  <h3>{{ $i18n.t('search') }}
                    {{ $i18n.t('found') }}: {{ paggination.total }} ({{ allAgents.length }})
                    </h3> 
                </div>
                </template>
            <template #end>
              
              <div class="ml-1 space-x-1 d-flex align-center">
                <div class="field-checkbox d-flex align-center">
                  <Checkbox id="binary" @change="setFiltersAndSubmit(true)" v-model="filters.is_active" :binary="true" />
                  <label for="binary" class="ml-1">{{ $t('active') }}</label>
                </div>
                <v-menu
                  v-model="userMenu"
                  :close-on-content-click="false"
                  offset-y
                  nudge-left="0"
                  nudge-right="0"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on" class="p-float-label p-input-icon-right">
                      <i class="pi pi-user" />
                      <InputText
                        type="text"
                        readonly
                        class="p-inputtext-sm pr-8"
                        :placeholder="getIdAndUsername"
                      >

                      </InputText>
                    </span>
                  </template>
                  <div class="input-menu__menu">
                      <div class="p-inputgroup">
                        <Button
                        v-if="filters.agent_id"
                        @click.stop="clearSelectedFilter('agent_id')"
                        icon="pi pi-times" class="p-button-danger"/>
                      <InputText
                    @input="debounceFilters('search')"
                    v-model="filters.agent_id"
                    class="p-inputtext-sm"
                    placeholder="ID"
                  />
                </div>
                      <div class="p-inputgroup">
                        <Button
                        v-if="filters.name"
                        @click.stop="clearSelectedFilter('name')"
                        icon="pi pi-times" class="p-button-danger"/>
                      <InputText
                    @input="debounceFilters('search')"
                    v-model="filters.name"
                    class="p-inputtext-sm"
                    :placeholder="$t('name')"
                  />
                </div>
                  </div>
                </v-menu>
                  <!-- <div class="p-inputgroup">
                    <Button
                    v-if="filters.agent_id"
                    @click.stop="clearSelectedFilter('agent_id')"
                    icon="pi pi-times" class="p-button-danger"/>
                      <InputText 
                      class="p-inputtext-sm"
                      :placeholder="$t('agent_id')" 
                      v-model="filters.agent_id" 
                      @keydown.enter="setFiltersAndSubmit('search')"
                      />
                      <Button @click="setFiltersAndSubmit('search')"
                      icon="pi pi-search" class="p-button-warning"/>
                  </div> -->
                <Button :disabled="loadingFlag" :loading="loadingFlag"
              @click="toCreateNewAgent"
               :label="$t('create_agent')" 
               icon="pi pi-plus" 
               class="p-button-sm p-button-success white-space"
               />
              </div>
            </template>
          </Toolbar>
              <!-- <v-chip-group>
                <Chip class="active-filter-chip mr-1" v-for="(value, key) in filteredActiveFilters" 
                :key="'filter' + key"
                  >
                  <template>
                    {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key, value) }}
                  </template>
                  <v-icon @click.stop="removeFilter(key, value)">mdi-close</v-icon>
                </Chip>
              </v-chip-group> -->
        </template>
        <template #content>
            <!-- 
          @row-expand="onRowExpand" 
             
          @row-select="selectAgent"
          selectionMode="single"
          filterDisplay="row" 
            -->
            <TreeTable
            v-if="!resetTree"
            sortMode="single"
            :value="treeData" 
            responsiveLayout="scroll"
            class="p-treetable-responsive p-treetable-sm"
            :loading="loadingFlag"
            @node-expand="loadChildren">
            <template #empty>
              <div class="text-center font-weight-bold">{{$t('no_data')}}</div>
            </template>
              <Column field="id" header="ID" :expander="filters.is_active ? true : false" :sortable="true" bodyStyle="min-width:70px">
              </Column>
              <Column field="login" :header="$t('agent')" >
              <template #body="slotProps">
                <div :class="{ 'c-red' : !slotProps.node.data.is_active }"
                >
                  {{ slotProps.node.data.login }}
                </div>
              </template>
              </Column>
              <!-- <Column field="login" header="Login"></Column> -->
              <Column field="level" :header="$t('level')">
                <template #body="slotProps">
                  <div :class="{ 'c-red' : !slotProps.node.data.is_active }"
                  >
                    {{ slotProps.node.data.level }}
                  </div>
                </template>
              </Column>
              <Column field="share" :header="$t('share')">
                <template #body="slotProps">
                  <div :class="{ 'c-red' : !slotProps.node.data.is_active }"
                  >
                    {{ slotProps.node.data.share }}
                  </div>
                </template>
              </Column>
              <Column field="credits_balance" :header="$t('balance')" bodyStyle="width:180px">
                <template #body="slotProps">
                  <div class="d-flex align-center px-1">
                    <Button icon="pi pi-minus" :class="{ 'c-red' : !slotProps.node.data.is_active }" @click="removeCredits(slotProps.node.data)" class="button-agent-balance "/>
                    <span class="mx-1 flex-1 text-center" :class="{ 'c-red' : !slotProps.node.data.is_active }">{{ getFormattedBalance(slotProps.node.data.credits_balance) }}</span>
                    <Button icon="pi pi-plus" :class="{ 'c-red' : !slotProps.node.data.is_active }" @click="addCredits(slotProps.node.data)" class="button-agent-balance "/>
                </div>
                  </template>
              </Column>
              <Column field="credits_on_players_balances" :header="$t('credit_players_balance')">
                <template #body="slotProps">
                  <div :class="{ 'c-red' : !slotProps.node.data.is_active }"
                  >
                    {{ slotProps.node.data.credits_on_players_balances }}
                  </div>
                </template>
              </Column>
              <Column field="jackpot_amount" :header="$t('jackpot_amount')">
                <template #body="slotProps">
                  <div :class="{ 'c-red' : !slotProps.node.data.is_active }"
                  >
                    {{ slotProps.node.data.jackpot_amount }}
                  </div>
                </template>
              </Column>
              <Column field="ggr" :header="$t('ggr')">
                <template #body="slotProps">
                  <div :class="{ 'c-red' : !slotProps.node.data.is_active }"
                  >
                    {{ slotProps.node.data.ggr }}
                  </div>
                </template>
              </Column>
              <Column field="currency" :header="$t('currency')">
                <template #body="slotProps">
                  <div :class="{ 'c-red' : !slotProps.node.data.is_active }"
                  >
                    {{ slotProps.node.data.currency }}
                  </div>
                </template>
              </Column>
              <Column field="total_subagents_count" :header="$t('subagents')" :sortable="true">
                <template #body="slotProps">
                  <div :class="{ 'c-red' : !slotProps.node.data.is_active }">{{ `${slotProps.node.data.first_level_subagents_count} (${slotProps.node.data.total_subagents_count})` }}</div>
                </template>
              </Column>
              <Column field="total_players" :header="$t('players')">
                <template #body="slotProps">
                  <div :class="{ 'c-red' : !slotProps.node.data.is_active }"
                  >
                    <router-link 
                    class='link-hover'
                    :to="{ name: 'agent_players', params: { agent: slotProps.node.data.id } }">{{ slotProps.node.data.total_players }}</router-link>
                  </div>
                </template>
              </Column>
              <Column :header="$t('actions')" bodyStyle="min-width:200px">
                <template #body="slotProps">
                  <div class="d-flex align-center">
                    <div class="space-x-1 d-flex align-center" :class="{ 'c-red' : !slotProps.node.data.is_active }">
                      <Button :label="$t('add_agent')" @click="toCreateSubAgent(slotProps)" icon="pi pi-plus" 
                      class="button-agent-balance white-space-nowrap" 
                      />
                      <!-- <Button :label="$t('buy_credits')" class="p-button-sm p-button-success _small" /> -->
                      <Button icon="pi pi-pencil" @click="toEditAgent(slotProps)" class="button-agent-balance _square" />
                      <Button v-if="slotProps.node.data.is_active" icon="pi pi-trash" @click="toDeleteAgent(slotProps)" class="button-agent-balance _square" />
                      <div 
                      v-if="!slotProps.node.data.is_active"
                       v-tooltip.top="$t('no_active_agent')" class="button-agent-balance _square d-flex align-center justify-center">
                      <i :class="{ 'c-red' : !slotProps.node.data.is_active }" class="pi pi-lock"></i>
                    </div>
                    </div>
                  </div>
                  </template>
            </Column>
            </TreeTable>
          </template>
        </Card>
        <games-paggination 
                          :pagginationObject="filters" 
                          :requestData="pagginationObject" 
                          @apply-filters="toUpdateNoActiveAgentWithTree" 
                          :isStatic="false"
                          />
    </div>
    <!-- @filter-new-player="filterNewPlayer" -->
    <modal-actions-agent 
    :isSubAgent="isSubAgent"
    :isNewAgent="isNewAgent"
              @added="toUpdateNoActiveAgentWithTree"
              :toAddDialog="toAddDialog" 
              @dialog="toClosedAgentModal"
              :parentId="selectedAgentID"
              :isEditAgent="isEditAgent"
              :isDeleteAgent="isDeleteAgent"
              :agent="selectedAgent"
              :triggerClearData="triggerClearData"
              />
    <balance-agent 
              @added="toResetTree"
              :type="typeOfBalance"
              :agent="selectedAgent"
              @dialog="toBalanceDialog = false"
              :toAddDialog="toBalanceDialog" />
    </v-main>
  </template>

<script>
import GamesPaggination from '@/views/GamesPaggination.vue'
import {debounce} from 'lodash';
import { mapGetters } from 'vuex';
import BalanceAgent from './BalanceAgent.vue'
import ModalActionsAgent from './ModalActionsAgent.vue';
    export default {
        components: {
          GamesPaggination,
          BalanceAgent,
          ModalActionsAgent   
        },
        name: "Agents",
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.$store.commit('setPageName', 'agents')
            })
        },
        computed: {
          ...mapGetters({
            tokenAgent: 'tokenUser',
            agents: 'agents/getAdminAgents',
            allAgents: 'agents/getAllAgents',
            treeData: 'agents/treeData',
            paggination: 'agents/getPaggination',
          }),
          pagginationObject(){
            return {
              items: this.agents,
              ...this.paggination
            }
          },
          isAgent(){
            if (this.tokenAgent === 'agent') {
              return 'agent';
            } else{
              return null;
            }
          },
          getIdAndUsername() {
      const { agent_id, name } = this.filters;
      // console.log('agent_id', agent_id);
      if (agent_id && name) {
      const trimFirstValue = agent_id.trim();
      const trimSecondValue = name.trim();
        if (!trimFirstValue && !trimSecondValue) {
          return this.$t('agent');
        } else if(trimFirstValue && trimSecondValue) {
          return `ID: ${trimFirstValue}, ${this.$t('name')}: ${trimSecondValue}`
        } 
      } else if (agent_id) {
        const trimFirstValue = typeof agent_id === 'string' ? agent_id.trim() : agent_id;
        return 'ID:' + ' ' + trimFirstValue;
      } else if (name) {
        const trimSecondValue = name.trim();
        return this.$t('name') + ': ' + trimSecondValue;
      } else {
        return this.$t('agent');
      }
    },
        },
        watch: {
          '$route.params.login': {
            handler(newV){
              if (newV) {
                this.filters.agent_id = newV;
                this.getAgents();
              }
            },
            immediate: true,
          },
        },
        data() {
            return {
              triggerClearData: false,
              resetTree: false,
                isEditAgent: false,
                isNewAgent: false,
                isSubAgent: false,
                userMenu: false,
                isDeleteAgent: false,
                loadingFlag: false,
                toAddDialog: false,
                toBalanceDialog: false,
                selectedAgentID: 0,
                selectedAgent: {},
                usedIds: [],
                typeOfBalance: 'deposit',
                startPage: 1,
                startSize: 25,
                filters:{
                  is_active: true,
                  level: null,
                  page: 1,
                  size: 25,
                  agent_id: null,
                  parent_agent_id: null,
                },
            }
        },
        mounted() {
          if (!this.agents.length) {
            this.getAgents();
          }
        },
        methods:{
          debounceFilters: debounce(async function(type) {
            this.setFiltersAndSubmit(type);
          }, 750),
          async toResetTree(){
            this.resetTree = true;
            this.$nextTick(() => {
              this.resetTree = false;
            })
            await this.getAgents();
            this.usedIds = [];
          },
          toResetTreeNoRequest() {
            this.resetTree = true;
            this.$nextTick(() => {
              this.resetTree = false;
            })
            this.usedIds = [];
          },
          async toGetAgentsWithChildren() {
    await this.getAgents();
    if (this.usedIds.length) {
      for (const agentId of this.usedIds) {
        await this.$store.dispatch('agents/loadAgentChildren', agentId)
          .then(children => {
            const node = this.findNodeById(agentId, this.treeData);
            // console.log('node', node);
            if (node) {
              this.$set(node, 'children', children.items.map(child => ({
                key: child.id.toString(),
                data: {
                  id: child.id,
                  name: child.name,
                  login: child.login,
                  email: agent.email,
                  phone: agent.phone,
                  level: child.level,
                  is_active: child.is_active,
                  share: child.share,
                  credits_balance: child.credits_balance,
                  credits_on_players_balances: child.credits_on_players_balances,
                  jackpot_amount: child.jackpot_amount,
                  ggr: child.ggr,
                  currency: child.currency,
                  total_subagents_count: child.total_subagents_count,
                  first_level_subagents_count: child.first_level_subagents_count,
                  total_players: child.total_players,
                },
                children: [], // Initially empty
                leaf: child.first_level_subagents_count === 0
              })));
              node.leaf = node.children.length === 0;
            }
          });
      }
      // console.log('this.treeData', this.treeData);
    }
  },
  findNodeById(id, nodes) {
    for (let node of nodes) {
      // console.log('node.key', node.key);
      // console.log('id', id);
      if (node && node.key == id) {
        return node;
      } else if (node.children && node.children.length) {
        const foundNode = this.findNodeById(id, node.children);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  },
          clearSelectedFilter(key) {
            this.filters[key] = null;
            this.toResetTreeNoRequest();
            const isSearch = true;
            this.getAgentsBySearch(isSearch);
          },
         async loadChildren(node) {
          // console.log('loadChildren', node);
           const agentId = Number(node.key);
          if (this.usedIds.includes(agentId)) {
              return;
            }
            this.usedIds.push(agentId);
            this.loadingFlag = true;
            this.filters.parent_agent_id = agentId;
            await this.$store.dispatch('agents/loadAgentChildren', agentId)
            .then(children => {
              node.children = children.items.map(child => ({
                key: child.id.toString(),
                data: {
                  id: child.id,
                  name: child.name,
                  login: child.login,
                  level: child.level,
                  is_active: child.is_active,
                  share: child.share,
                  parent_agent: child.parent_agent,
                  credits_balance: child.credits_balance,
                  credits_on_players_balances: child.credits_on_players_balances,
                  jackpot_amount: child.jackpot_amount,
                  ggr: child.ggr,
                  currency: child.currency,
                  total_subagents_count: child.total_subagents_count,
                  first_level_subagents_count: child.first_level_subagents_count,
                  total_players: child.total_players,
                },
                children: [], // Опять же, изначально пустой
                leaf: child.first_level_subagents_count === 0
              }));
              node.leaf = node.children.length === 0;
            });
            this.loadingFlag = false;
            // console.log(node);
      },
          getFormattedBalance(balance) {
            let formattedNumber = balance.toFixed(2);

          formattedNumber = Number(formattedNumber).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });

          return formattedNumber;
          },
          removeCredits(agent){
            this.selectedAgent = agent;
            this.toBalanceDialog = true;
            this.typeOfBalance = 'withdraw'
          }, 
          addCredits(agent){
            this.selectedAgent = agent;
            this.toBalanceDialog = true;
            this.typeOfBalance = 'deposit'
          },
          setFiltersAndSubmit(isSearch) {
            this.filters.page = this.startPage;
            this.filters.size = this.startSize;
            this.toResetTreeNoRequest();
            this.usedIds = [];
            if (isSearch) {
              this.getAgentsBySearch(isSearch);
            } else{
              this.getAgents();
            }
          },
          async getAgents() {
            this.isEditAgent = false;
            this.isDeleteAgent = false;
            this.toAddDialog = false;
            this.toBalanceDialog = false;
            this.loadingFlag = true;
            this.filters.parent_agent_id = null;
            // this.filters.level = 0;
            if (!this.filters.is_active) {
              this.filters.level = null;
            } else if (this.isAgent) {
              this.filters.level = 1;
            } else{ 
              this.filters.level = 0;
            }
            try {
              
              await this.$store.dispatch('agents/awaitGetAllAdminAgents', {type: this.isAgent, filters: this.filters});
            } catch (error) {
              this.$toast.add({ severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000 })
            } finally{
              this.$store.commit('paggination/SET_FILTERS', this.filters);
              this.$store.commit('paggination/SET_LOADING_FLAG', false);
              this.loadingFlag = false;
            }
          },
          toUpdateNoActiveAgentWithTree() {
            this.toResetTreeNoRequest();  
            this.getAgentsBySearch(true);
          },
          async getAgentsBySearch(isSearch = false) {
            this.isEditAgent = false;
            this.isDeleteAgent = false;
            this.toAddDialog = false;
            this.toBalanceDialog = false;
            this.loadingFlag = true;
            this.filters.parent_agent_id = null;
            //if (!isSearch) {
            //  if (!this.filters.is_active) {
            //    this.filters.level = null;
            //  } else if (!this.isAgent && this.filters.is_active) {
            //    this.filters.level = 0;
            //  }
            //} else{
            //  console.log(isSearch);
            //  //this.filters.level = null;
            //}
            if (!this.filters.is_active) {
              this.filters.level = null;
            } else if (this.isAgent) {
              this.filters.level = 1;
            } else{ 
                  this.filters.level = 0;
            }
            if (isSearch === 'search') {
                  this.filters.level = null;
                }
            try {
              await this.$store.dispatch('agents/awaitGetAllAdminAgents', {type: this.isAgent, filters: this.filters, isSearch: true});
            } catch (error) {
              this.$toast.add({ severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000 })
            } finally{
              this.$store.commit('paggination/SET_FILTERS', this.filters);
              this.$store.commit('paggination/SET_LOADING_FLAG', false);
              this.loadingFlag = false;
            }
          },
          toCreateNewAgent() {
            this.toAddDialog = true;
            this.isNewAgent = true;
              //this.selectedAgent = {};
              //this.selectedAgentID = null;
          },
          toCreateSubAgent(agent) {
            this.toAddDialog = true;
            this.isSubAgent = true;
              this.selectedAgentID = Number(agent.node.key);
          },
          toEditAgent(agent) {
            this.toAddDialog = true;
            // this.triggerClearData = true
            this.selectedAgent = agent.node.data;
            this.isEditAgent = true;
          },
          toDeleteAgent(agent) {
            this.triggerClearData = true
            this.selectedAgent = agent.node.data;
            this.toAddDialog = true;
            this.isEditAgent = false;
            this.isDeleteAgent = true;  
          },
          toClosedAgentModal(){
            this.triggerClearData = false;
            this.toAddDialog = false;
            this.selectedAgent = {};
            this.selectedAgentID = null;
            this.isEditAgent = false;
            this.isSubAgent = false;
            this.isNewAgent = false;
            this.isDeleteAgent = false;
          },
        },
    }
</script>

<style lang="scss" scoped>

</style>